import React, { useState }  from 'react'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { makeStyles } from '@material-ui/core/styles'
import { validateContact } from '../../utils/validateContact'
import Plan from './components/plan/plan'
import Phone from './components/phone/phone'
import Contact from './components/contact/contact'
import Address from './components/address/address'
import Success from './components/success/success'
import Steps from './components/steps/steps'
import { isMobile, isMobileOnly } from 'react-device-detect'  
import { reqSendEmail } from '../../utils/request'

const Form = ({ companyName, companyColor1, companyColor2 }) => {
    const [state, setState] = useState({
        phoneId: "",
        phoneName: "",
        planId: "",
        planName: "",
        planPrice: "",
        rut: "",
        firstName: "",
        pLastName: "",
        mLastName: "",
        corporateEmail: "",
        personalEmail: "",
        branchOffice: "",
        cui: "",
        officePhoneNumber: "",
        phoneNumber: "",
        mnp: false,
        terms: false,
        address: "",
        addressDetail: ""
    })

    const useStyles = makeStyles(theme => ({
        button: {
            backgroundColor: companyColor1+'!important',
            color: '#fff',
            '&::hover': {
                backgroundColor: companyColor1+'!important'
            }
        },
    }))
  
    const [step, setStep] = useState(1)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState('')
  
    const updateState = (name, value) => {
        setError(null)
        setState((prevState) => ({...prevState, [name] : value}))
    }

    const validateStep = () => {
        if(step === 1) (state.phoneId !== '') ? nextStep() : showError(1)
        if(step === 2) (state.planId !== '') ? nextStep() : showError(2)
        if(step === 3){
            var contactError = validateContact(state)
            if(contactError){
                showError(3, contactError)
            }else{
                nextStep()
            }
        }
        if(step === 4) (state.address !== '') ? nextStep() : showError(4)
    }

    const prevStep = () => {
        setStep(step - 1)
        setError(null)
    }

    const nextStep = () => {
        setStep(step + 1)
        setError(null)
        if(step === 4){
            sendEmail()
        }
    }

    const showError = (error, errorMessage = '') => {
        const newError = (error === 1) ? 'Por favor selecciona equipo' :
            (error === 2) ? 'Por favor selecciona plan' : 
                (error === 3) ? errorMessage :
                    (error === 4) ? 'Por favor selecciona dirección' :null
        setError(newError)
    }

    const sendEmail = () => {
        (async () => {
            try{
                setLoading(true)
                const resSendEmail = await reqSendEmail(state)
                if(resSendEmail.data.success){
                    setEmailSuccess('OK')
                }else{
                    setEmailSuccess('ERROR')
                }
                setLoading(false)
            }catch(error){}
        })()
    }

    const classes = useStyles()
  
    return (
        <React.Fragment>      
            <Steps step={step} companyColor1={companyColor1} />
            <div className="form" style={{ maxHeight: (isMobileOnly && step === 1) ? '428px' : (!isMobileOnly) ? '400px' : 'unset'}}>
                {(step === 1) ? <Phone phoneId={state.phoneId} phoneName={state.phoneName} step={step} updateState={updateState} /> :
                (step === 2) ? <Plan planId={state.planId} planName={state.planName} planPrice={state.planPrice} updateState={updateState} step={step} /> :
                (step === 3) ? <Contact state={state} updateState={updateState} /> :
                (step === 4) ? <Address address={state.address} updateState={updateState} /> :
                (step === 5) ? <Success loading={loading} emailSuccess={emailSuccess} companyColor1={companyColor1} sendEmail={sendEmail} /> : (null)}
            </div>
            {step !== 5 &&
                <React.Fragment>
                    {isMobile &&
                        <div className="col-xs-12">
                            <div className="row arrow-buttons row justify-content-between px-4">
                                <div className="col-xs-6">
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        disabled={step === 1 ? true : false}
                                        startIcon={<ArrowLeftIcon style={{fontSize: 28, marginLeft: "-10px", marginBottom: "2px"}} />}            
                                        onClick={() => prevStep()}
                                    >
                                        Anterior
                                    </Button>
                                </div>
                                <div className="col-xs-6">
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        endIcon={<ArrowRightIcon style={{fontSize: 28, marginLeft: "-10px", marginBottom: "2px"}} />}
                                        onClick={() => validateStep(step + 1)}
                                    >
                                        {step !== 4 ? "Siguiente": "Enviar"}
                                    </Button>
                                </div>
                            </div>
                            <div className="row text-center error-message">
                                <span>{error}</span>
                            </div>
                        </div>
                    }
                    {!isMobile &&
                        <div className="row arrow-buttons row justify-content-between px-4">
                            <div className="col-lg-3">
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    disabled={step === 1 ? true : false}
                                    startIcon={<ArrowLeftIcon style={{fontSize: 28, marginLeft: "-10px", marginBottom: "2px"}} />}            
                                    onClick={() => prevStep()}
                                >
                                    Anterior
                                </Button>
                            </div>
                            <div className="col-lg-6 text-right error-message">
                                <span>{error}</span>
                            </div>
                            <div className="col-lg-3 next-button-container">
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    endIcon={<ArrowRightIcon style={{fontSize: 28, marginLeft: "-10px", marginBottom: "2px"}} />}
                                    onClick={() => validateStep(step + 1)}
                                >
                                    {step !== 4 ? "Siguiente": "Enviar"}
                                </Button>
                            </div>
                        </div>
                    }
                </React.Fragment> 
            }
        </React.Fragment> 
    )
}
  
export default Form