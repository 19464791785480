import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

export default function Steps({ step, companyColor1 }) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    step: {
      '&> span > span > .MuiStepIcon-active, > span > span > .MuiStepIcon-completed': {
        color: companyColor1+'!important'
      },
    }
  }))

  const classes = useStyles()

  const getSteps = () => {
    return ['Equipo', 'Plan', 'Contacto', 'Dirección', 'Éxito']
  }

  const steps = getSteps()
  
  return (
    <div id="steps" className={classes.root}>
      <Stepper activeStep={step - 1} alternativeLabel>
        {steps.map(label => (      
            <Step className={classes.step} key={label}>
              <StepLabel className="stepLabel">{label}</StepLabel>
            </Step>
        ))}
      </Stepper>
    </div>
  )
}