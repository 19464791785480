import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import TextField from '@material-ui/core/TextField'
import Logo from '../../../../images/movistar-logo-small.png'

const Address = ({ state, updateState }) => {
    const [address, setAddress] = useState("")
    const [addressDetail, setAddressDetail] = useState("")

    const [coordinates, setCoordinates] = useState({
        lat: -33.436992,
        lng: -70.634494,
    })

    const handleSelect = async value => {
        const results = await geocodeByAddress(value)
        const latLng = await getLatLng(results[0])
        setAddress(value)
        setCoordinates(latLng)
        updateState('address', value)
    }

    const handleChangeAddressDetail = (value) => {
        setAddressDetail(value)
        updateState('addressDetail', value)
    }

    const MapWithAMarker = withGoogleMap(props =>
        <GoogleMap
          defaultZoom={13}
          defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
        >
          <Marker
            position={{ lat: coordinates.lat, lng: coordinates.lng }}
          />
        </GoogleMap>
    );

    return (
        <React.Fragment>
            <Typography className="form-title" variant="h6" gutterBottom>
                <img className="pr-1 pb-2" src={Logo} alt="Logo"/> Ingresa tu dirección
            </Typography>
            <div className="row py-0">
                <div className="col-lg-8 py-0" id="addressInput">
                    <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField
                                required
                                className="mb-2"
                                {...getInputProps({ placeholder: "Ingresa dirección" })}
                                fullWidth
                            />
                            <div style={{ position: "absolute", top: "40px", zIndex: "10" }}>
                            {loading ? <div>...loading</div> : null}

                            {suggestions.map((suggestion, index) => {
                                const style = {
                                backgroundColor: suggestion.active ? "#00a9e0" : "#fff"
                                };

                                return (
                                <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                                    {suggestion.description}
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div className="col-lg-4 addressInputDetail">
                    <TextField
                        required
                        className="mb-2"
                        placeholder="Piso, pasaje, etc."
                        fullWidth
                        value={addressDetail}
                        onChange={(e) => handleChangeAddressDetail(e.target.value)}
                    />
                </div>
            </div>
            <MapWithAMarker
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </React.Fragment>
    )
}

export default Address