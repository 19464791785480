import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../../../images/movistar-logo-small.png'
import Spinner from '../../../../images/spinner.svg'
import SuccessImage from '../../../../images/success.png'

const Success = ({ loading, emailSuccess, companyColor1, sendEmail }) => {
    const [message, setMessage] = useState('')

    useEffect(() => {
        if(loading){
            setMessage('Enviando...')
        }else if(!loading && emailSuccess === 'OK'){
            setMessage('¡Datos ingresados con éxito!')
        }else if(!loading && emailSuccess === 'ERROR'){
            setMessage('Error de envío de correo')
        }
    }, [emailSuccess, loading])

    const useStyles = makeStyles(theme => ({
        button: {
            backgroundColor: companyColor1+'!important',
            color: '#fff',
            '&::hover': {
                backgroundColor: companyColor1+'!important'
            }
        },
    }))

    const classes = useStyles()

    return (
        <React.Fragment>
            <Typography className="form-title" variant="h6" gutterBottom>
                <img className="pr-1 pb-2" src={Logo} alt="Logo"/> {message}
            </Typography>
            {loading &&
                <div className="spinner"><img src={Spinner} alt="spinner" /></div>
            }
            {!loading && emailSuccess === 'OK' &&
                <div className="text-center">
                    <img className="successImage" src={SuccessImage} style={{ width: "200px" }} alt="Logo"/>
                    <p className="mt-4">Tu solicitud ha sido ingresada exitosamente a la plataforma de Afubach.</p>
                    <p className="mt-4 mb-1">Se ha enviado un correo a tu email corporativo y personal con los datos ingresados.</p>
                    <p className="mb-1">Plataforma te contactará a la brevedad para concretar la solicitud.</p>
                    <p>Ante cualquier consulta no dudes en contactarte con nosotros a <strong>plataforma@afubach.cl</strong></p>
                </div>
            }
            {!loading && emailSuccess === 'ERROR' &&
                <div className="text-center">
                    <p className="errorMessage">Se ha producido un error, sin embargo puedes intentarlo nuevamente.</p>
                    <Button
                        variant="contained"
                        className={classes.button}
                        endIcon={<ArrowRightIcon style={{fontSize: 28, marginLeft: "-10px", marginBottom: "2px"}} />}
                        onClick={() => sendEmail()}
                    >
                        Reintentar envío
                    </Button>
                </div>
            }
        </React.Fragment>
    )
}

export default Success