import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Slider from 'react-slick'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { isMobileOnly } from 'react-device-detect'  
import Logo from '../../../../images/movistar-logo-small.png'
import phones from '../../../../data/phones.json'

const Phone = ({ phoneId, phoneName, step, updateState }) => {
    const [arrPhones, setArrPhones] = useState(phones)
 
    useEffect(() => {
        if(phoneId !== ''){
            const phoneAux = phones.filter(phone => phone.id === phoneId)
            const phoneFiltered = phones.filter(phone => phone.id !== phoneId)
            const newPhoneArr = []
            newPhoneArr.push({id: phoneAux[0].id, name: phoneAux[0].name, camera: phoneAux[0].camera, cameraFront: phoneAux[0].cameraFront, screen: phoneAux[0].screen, memory: phoneAux[0].memory, image: phoneAux[0].image })
            phoneFiltered.forEach(element => {
                newPhoneArr.push({ id: element.id, name: element.name, camera: element.camera, cameraFront: element.cameraFront, screen: element.screen, memory: element.memory, image: element.image })
            })
            setArrPhones(newPhoneArr)
        }
    }, [step])

    const handleChange = (newPhoneId, newPhoneName) => {
        newPhoneId = (newPhoneId === phoneId) ? '' : newPhoneId
        newPhoneName = (newPhoneName === phoneName) ? '' : newPhoneName
        updateState('phoneId', newPhoneId)
        updateState('phoneName', newPhoneName)
    }

    const NextArrow = ({onClick}) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight />
            </div>
        )
    }

    const PrevArrow = ({onClick}) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft />
            </div>
        )
    }

    const [imageIndex, setImageIndex] = useState(0)

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: (isMobileOnly) ? 1 : 3,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
    }

    return (
        <React.Fragment>
            <Typography className="form-title" variant="h6" gutterBottom>
                <img className="pr-1 pb-2" src={Logo} alt="Logo"/> Selecciona equipo
            </Typography>
            <Slider {...settings}>
                {arrPhones.map((phone, index) => (
                    <div key={index} className={index === imageIndex ? 'row slide activeSlide' : 'slide'}>
                        <div className="row">
                            <div className="col-sm col-phone-left px-0">
                                <img src={process.env.PUBLIC_URL+'/images/'+phone.image} alt={phone.id} height="350"/>
                            </div>
                            <div className="col-sm col-phone-right slick-col">
                                <div className="row position-absolute justify-content-center slick-detail-col">
                                    <span className="slick-detail-col-span">{phone.name}</span>
                                </div>
                                <div className="position-absolute slick-detail-col-text">
                                    <div className="row slick-detail-row slick-detail-row-camera">
                                        <p className="mb-0">Cámara Principal</p>
                                        <Divider className="slick-detail-divider" />
                                        <p className="slick-detail-p">{phone.camera}</p>
                                    </div>
                                    <div className="row slick-detail-row">
                                        <p className="mb-0">Cámara Frontal</p>
                                        <Divider className="slick-detail-divider" />
                                        <p className="slick-detail-p">{phone.cameraFront}</p>
                                    </div>
                                    <div className="row slick-detail-row">
                                        <p className="mb-0">Pantalla</p>
                                        <Divider className="slick-detail-divider" />
                                        <p className="slick-detail-p">{phone.screen}</p>
                                    </div>
                                    <div className="row slick-detail-row slick-detail-row-memory">
                                        <p className="mb-0">Memoria</p>
                                        <Divider className="slick-detail-divider" />
                                        <p className="slick-detail-p">{phone.memory}</p>
                                    </div>
                                    <div className="row position-absolute justify-content-center text-center mt-3 slick-detail-col-button">
                                        <Button variant="contained" className={(phoneId === phone.id) ? 'phone-button-selected' : 'phone-button'} onClick={() => handleChange(phone.id, phone.name)}>
                                            {phoneId === phone.id ? 'SELECCIONADO' : 'SELECCIONAR'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </React.Fragment>
    )
}

export default Phone