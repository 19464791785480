import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { isMobileOnly } from 'react-device-detect'  
import Logo from '../../../../images/movistar-logo-small.png'

const Contact = ({ state, updateState }) => {
    const handleChange = (name, e) => {
        updateState(name, e.target.value)
    }

    const handleChangeBoolean = (name, e) => {
        updateState(name, !state[name])
    }

    const BlueCheckbox = withStyles({
        root: {
          color: '#00a9e0',
          '&$checked': {
            color: '#00a9e0',
          },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <React.Fragment>
            <Typography className="form-title" variant="h6" gutterBottom>
                <img className="pr-1 pb-2" src={Logo} alt="Logo"/> Ingresa datos de socio
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="rut"
                        name="rut"
                        label="Rut (formato: 11111111-1)"
                        fullWidth
                        value={state.rut}
                        onChange={(e) => handleChange("rut", e)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="Nombres"
                        value={state.firstName}
                        onChange={(e) => handleChange("firstName", e)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="pLastName"
                        name="pLastName"
                        label="Apellido paterno"
                        value={state.pLastName}
                        onChange={(e) => handleChange("pLastName", e)}                
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required    
                        id="mLastName"
                        name="mLastName"
                        label="Apellido materno"
                        value={state.mLastName}
                        onChange={(e) => handleChange("mLastName", e)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="corporateEmail"
                        name="corporateEmail"
                        label="Email corporativo"
                        value={state.corporateEmail}
                        onChange={(e) => handleChange("corporateEmail", e)}
                        fullWidth
                    />
                </Grid>            
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="personalEmail"
                        name="personalEmail"
                        label="Email personal"
                        value={state.personalEmail}
                        onChange={(e) => handleChange("personalEmail", e)}
                        fullWidth            
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="branchOffice"
                        name="branchOffice"
                        label="Sucursal"
                        value={state.branchOffice}
                        onChange={(e) => handleChange("branchOffice", e)}
                        fullWidth            
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cui"
                        name="cui"
                        label="CUI de operaciones"
                        value={state.cui}
                        onChange={(e) => handleChange("cui", e)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="officePhoneNumber"
                        name="officePhoneNumber"
                        label="Anexo telefónico"
                        value={state.officePhoneNumber}
                        onChange={(e) => handleChange("officePhoneNumber", e)}
                        fullWidth            
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label={(isMobileOnly) ? "Celular (formato: 988887777)" : "Celular de contacto (formato: 988887777)"}
                        value={state.phoneNumber}
                        onChange={(e) => handleChange("phoneNumber", e)}
                        fullWidth
                    />
                </Grid>
                <Grid className="contactCheckbox" item xs={12} sm={6}>
                    <FormControlLabel
                        control={<BlueCheckbox checked={state.mnp} onChange={(e) => handleChangeBoolean("mnp", e)} name="mnp" id="mnp" />}
                        label="Portabilidad desde otra compañía"
                    />
                </Grid>
                <Grid className="contactCheckbox" item xs={12} sm={6}>
                    <FormControlLabel
                        control={<BlueCheckbox checked={state.terms} onChange={(e) => handleChangeBoolean("terms", e)} name="terms" id="terms" />}
                        label="Acepto términos y condiciones"
                    />
                    <a className="terms-link" href='#'>Ver más</a>
                </Grid>
            </Grid>        
        </React.Fragment>
  )
}

export default Contact