import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
//import * as serviceWorker from './serviceWorker'

// import PublicRoute from './publicRoutes'
// import PrivateRoute from './privateRoutes'
import App from './App'
import PageNotFound from './pageNotFound/pageNotFound'
import Login from './admin/login/login'
import Front from './front/front'

render(
    <Router>
        <App>
            <Switch>
                <Route path="/tF3w4t2T47rW2U" exact component={Login} />
                <Route path="/form/:name" exact component={Front} />
                <Route render={()=> <Redirect to="/form/ejemplo-movistar"/>} />
            </Switch>
        </App> 
    </Router>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
