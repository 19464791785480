import React from 'react'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const PlanWide = ({ plans, handleChange, planId }) => {
    return (
        <div className="row plan-row">
            {plans.map((plan, index) => (
                <div key={index} className="col-lg-3 plan-container">
                    <div className="col-md-12 plan">
                        <div className="row justify-content-center">
                            <span className="plan-name">{plan.name}</span>
                        </div>
                        <div className="row justify-content-center">
                            <p>
                                <span className="plan-price-small">$</span>
                                <span className="plan-price">{plan.price}</span>
                            </p>
                        </div>
                        <div className="row justify-content-center" style={{marginTop: "-20px"}}>
                            <span className="plan-detail-name">Minutos</span>
                        </div>
                        <Divider className="plan-detail-divider"/>
                        <div className="row justify-content-center plan-detail-content">
                            <span className="plan-detail-content-span">{plan.minutes}</span>
                        </div>
                        <div className="row justify-content-center plan-detail-content"> 
                            <span className="plan-detail-name">Navegación</span>
                        </div>
                        <Divider className="plan-detail-divider"/>
                        <div className="row justify-content-center plan-detail-content">
                            <span className="plan-detail-content-span">{plan.gb}</span>
                        </div>
                        <Divider className="plan-detail-divider-last" />
                        <div className="row justify-content-center" style={{marginTop: "-4px"}}>
                            <span style={{color: "#fff", fontSize: "14px"}}>Código plan {plan.code}</span>
                        </div>
                        <div className="row justify-content-center" style={{marginTop: "8px"}}>
                            <Button variant="contained"  className={(planId === plan.id) ? 'plan-button-selected' : 'plan-button'}  onClick={() => handleChange(plan.id, plan.name, plan.price)}>
                                {planId === plan.id ? 'SELECCIONADO' : 'SELECCIONAR'}
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default PlanWide
