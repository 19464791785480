import React from 'react'
import Logo from '../../images/movistar-logo.png'

const Title = ({ companyName }) => {
    return(
        <div className="title align-self-center title-main">
            <div className="row pb-2 px-3 justify-content-center mr-2">
                <img src={Logo} className="logo-movistar" alt="" />
            </div>
            <div className="row pb-2 px-3 title-row-title">
                <h1>
                    Descubre los increíbles planes que Afubach tiene para ti
                </h1>
            </div>
            <div className="row px-3 title-row-description">  
                <div className="col-12">         
                    <div className="row">                
                        <h5>
                        Descubre los planes y equipos que {companyName} tiene exclusivamente para los usuarios de Afubach. Promoción válida desde el 01 de marzo al 01 de abril de 2020.
                        </h5>       
                    </div> 
                </div>     
            </div>
        </div>
    )
}

export default Title

