import { isEmail, isRutValid, isInteger, isPhone } from './validate'

export const validateContact = (values) => {
    var error = null;
    if(!values.terms){
        error = 'Debe aceptar términos y condiciones'
    } 
    if(!isPhone(values.phoneNumber)){
        error = 'Por favor ingresa un celular válido'
    } 
    if(!values.phoneNumber){
        error = 'Por favor ingresa celular'
    } 
    if(!isInteger(values.officePhoneNumber)){
        error = 'Por favor ingresa un anexo telefónico válido'
    } 
    if(!values.officePhoneNumber){
        error = 'Por favor ingresa anexo telefónico'
    } 
    if(!isInteger(values.cui)){
        error = 'Por favor ingresa un CUI de operaciones válido'
    }
    if(!values.cui){
        error = 'Por favor ingresa CUI de operaciones'
    } 
    if(!values.branchOffice){
        error = 'Por favor ingresa sucursal'
    } 
    if(!isEmail(values.personalEmail)){
        error = 'Por favor ingresa un email personal válido'
    } 
    if(!values.personalEmail){
        error = 'Por favor ingresa email personal'
    } 
    if(!isEmail(values.corporateEmail)){
        error = 'Por favor ingresa un email corporativo válido'
    }
    if(values.corporateEmail === values.personalEmail){
        error = 'Email personal debe ser diferente de email corporativo'
    }
    if(!values.corporateEmail){
        error = 'Por favor ingresa email corporativo'
    } 
    if(!values.mLastName){
        error = 'Por favor ingresa apellido materno de socio'
    } 
    if(!values.pLastName){
        error = 'Por favor ingresa apellido paterno de socio'
    } 
    if(!values.firstName){
        error = 'Por favor ingresa nombre de socio'
    } 
    if(!isRutValid(values.rut)){
        error = 'Por favor ingresa rut de socio válido'
    }
    if(!values.rut){
        error = 'Por favor ingresa rut de socio'
    } 
    return error
}