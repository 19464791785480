import React from 'react'

const Login = () => {
    return(
        <section>
            <p>login</p>
        </section>
    )
}

export default Login
