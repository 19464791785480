import axios from 'axios'
import config from '../config/config.json'

export async function reqSendEmail(state){
    const params = new URLSearchParams()
    params.append('firstName', state.firstName)
    params.append('pLastName', state.pLastName)
    params.append('mLastName', state.mLastName)
    params.append('phoneName', state.phoneName)
    params.append('planName', state.planName)
    params.append('planPrice', state.planPrice)
    params.append('corporateEmail', state.corporateEmail)
    params.append('personalEmail', state.personalEmail)
    params.append('branchOffice', state.branchOffice)
    params.append('phoneNumber', state.phoneNumber)
    params.append('mnp', (state.mnp) ? 'Sí' : 'No')
    params.append('address', state.address)
    params.append('addressDetail', state.addressDetail)
    const request = await axios({
        method: 'post',
        url: config.backend+'email/sendEmail',
        data: params
    });
    return request
};