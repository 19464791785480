import React, { useState, useEffect } from 'react'
import Title from './title/title'
import Form from './form/form'
import { isMobile } from 'react-device-detect'  
import Campaigns from '../data/campaigns.json'
import Companies from '../data/companies.json'

const Front = () => {
    const [state, setState] = useState({    
        companyName: "",
        companyColor1: "",
        companyColor2: "",
    });

    useEffect(() => {
        const path = new URL(window.location.href).pathname.split('/')[2];
        var campaign = Campaigns.filter((campaign) => {
            if(campaign.name === path) {
                return campaign
            }
        })[0];
        if(!campaign){
            window.location.replace('ejemplo-movistar')
        }
        var company = Companies.filter((company) => {
            if(company.id === campaign.companyId) {
                return company
            }
        })[0];
        setState(prevState => ({ 
            ...prevState,
            companyName: company.name,
            companyColor1: company.color1,
            companyColor2: company.color2
        }));
    }, [state.companyName]);

    return (
        <div className="container-fluid" style={{ background: 'linear-gradient(192deg, rgb(0, 197, 255) 0%, rgb(0, 107, 138) 100%)' }}>    
            <div className="col-lg-12 h-100">
                <div className="row h-100">
                    <div className="col-lg-4 col-sm-12 col-xs-12 d-flex">
                        <Title companyName={state.companyName}/>
                    </div>
                    <div className="col-lg-8 col-sm-12 col-xs-12 col-form">
                        <Form companyName={state.companyName} companyColor1={state.companyColor1} companyColor2={state.companyColor2} />
                    </div>
                    {isMobile &&
                        <div className="row" style={{ color: "transparent" }}>
                            Portfolio Rudy Alvarado
                        </div>
                    }
                </div>
            </div>                                         
        </div>          
    )
}

export default Front