import React from 'react'
import Typography from '@material-ui/core/Typography'
import PlanWide from './planWide'
import PlanMobile from './planMobile'
import { isMobile } from 'react-device-detect' 
import Logo from '../../../../images/movistar-logo-small.png'
import plans from '../../../../data/plans.json'

const Plan = ({ planId, planName, planPrice, updateState, step }) => {
    const handleChange = (newPlanId, newPlanName, newPlanPrice) => {
        newPlanId = (newPlanId === planId) ? '' : newPlanId
        newPlanName = (newPlanName === planName) ? '' : newPlanName
        newPlanPrice = (newPlanPrice === planPrice) ? '' : newPlanPrice
        updateState('planId', newPlanId)
        updateState('planName', newPlanName)
        updateState('planPrice', newPlanPrice)
    }

    return (
        <React.Fragment>
            <Typography className="form-title" variant="h6" gutterBottom>
                <img className="pr-1 pb-2" src={Logo} alt="Logo"/> Selecciona plan
            </Typography>
            {isMobile && window.screen.width < 1024 ?
                <PlanMobile plans={plans} handleChange={handleChange} planId={planId} step={step} />
            : 
                <PlanWide plans={plans} handleChange={handleChange} planId={planId} />
            }
            
        </React.Fragment>
    )
}

export default Plan