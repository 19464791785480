import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { isMobileOnly } from 'react-device-detect' 
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const PlanMobile = ({ plans, handleChange, planId, step }) => {
    const [arrPlans, setArrPlans] = useState(plans)

    useEffect(() => {
        if(planId !== ''){
            const planAux = plans.filter(plan => plan.id === planId)
            const planFiltered = plans.filter(plan => plan.id !== planId)
            const newPlanArr = []
            newPlanArr.push({id: planAux[0].id, name: planAux[0].name, price: planAux[0].price, minutes: planAux[0].minutes, gb: planAux[0].gb, code: planAux[0].code })
            planFiltered.forEach(element => {
                newPlanArr.push({ id: element.id, name: element.name, price: element.price, minutes: element.minutes, gb: element.gb, code: element.code })
            })
            setArrPlans(newPlanArr)
        }else{
            setArrPlans(plans)
        }
    }, [step])

    const NextArrow = ({onClick}) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight />
            </div>
        )
    }
    
    const PrevArrow = ({onClick}) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft />
            </div>
        )
    }
    
    const [imageIndex, setImageIndex] = useState(0)
    
    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
    }

    return (
        <Slider {...settings}>
            {arrPlans.map((plan, index) => (
                <div key={index} className="justify-content-center d-flex">
                    <div className={index === imageIndex ? 'col-lg-3 plan-container slide activeSlide' : 'col-lg-3 plan-container slide'} style={isMobileOnly ? {width: "80%"} : {width: "70%", marginLeft: "40px"}}>
                        <div className="col-md-12 plan">
                            <div className="row justify-content-center">
                                <span className="plan-name">{plan.name}</span>
                            </div>
                            <div className="row justify-content-center">
                                <p>
                                    <span className="plan-price-small">$</span>
                                    <span className="plan-price">{plan.price}</span>
                                </p>
                            </div>
                            <div className="row justify-content-center" style={{marginTop: "-20px"}}>
                                <span className="plan-detail-name">Minutos</span>
                            </div>
                            <Divider className="plan-detail-divider"/>
                            <div className="row justify-content-center plan-detail-content">
                                <span className="plan-detail-content-span">{plan.minutes}</span>
                            </div>
                            <div className="row justify-content-center plan-detail-content"> 
                                <span className="plan-detail-name">Navegación</span>
                            </div>
                            <Divider className="plan-detail-divider"/>
                            <div className="row justify-content-center plan-detail-content">
                                <span className="plan-detail-content-span">{plan.gb}</span>
                            </div>
                            <Divider className="plan-detail-divider-last" />
                            <div className="row justify-content-center" style={{marginTop: "-4px"}}>
                                <span style={{color: "#fff", fontSize: "14px"}}>Código plan {plan.code}</span>
                            </div>
                            <div className="row justify-content-center" style={{marginTop: "8px"}}>
                                <Button variant="contained"  className={(planId === plan.id) ? 'plan-button-selected' : 'plan-button'}  onClick={() => handleChange(plan.id, plan.name, plan.price)}>
                                    {planId === plan.id ? 'SELECCIONADO' : 'SELECCIONAR'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    )
}

export default PlanMobile
